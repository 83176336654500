import { UserRoles } from "@/enums/user.enum";
import useAuthStore from "@/store/useAuthStore";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  children?: JSX.Element;
  isLoggedIn?: boolean;
  isAdmin?: boolean;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const user = useAuthStore((state) => state.user);
  const fetchProfileAsync = useAuthStore((state) => state.fetchProfileAsync);

  const { children } = props;
  const location = useLocation();

  const isLoggedIn = accessToken && accessToken.length > 0;

  useEffect(() => {
    if (isLoggedIn) {
      fetchProfileAsync();
    }
  }, [fetchProfileAsync, isLoggedIn]);

  if (!isLoggedIn || !user) {
    const currentPath = location.pathname;
    const redirectTo = `${
      props.isAdmin ? "/admin/auth/login" : "/auth/login"
    }?path=${encodeURIComponent(currentPath)}`;

    return <Navigate to={redirectTo} replace />;
  }

  // redirect to actual path

  if (props.isAdmin && user.role !== UserRoles.ADMIN) {
    return <Navigate to={"/"} replace />;
  }

  if (!props.isAdmin && user.role !== UserRoles.PATIENT) {
    return <Navigate to={"/admin"} replace />;
  }

  return children;
};

export default ProtectedRoute;
