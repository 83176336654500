interface FormItemProps {
  label?: string;
  children?: JSX.Element;
  labelClass?: string;
  inputClass?: string;
  required?: boolean;
  className?: string;
  verticalAlign?: string;
  suffix?: string;
  isVertical?: boolean;
  error?: string;
}

export const FormItem = (props: FormItemProps) => {
  const {
    label,
    children,
    labelClass,
    inputClass,
    required,
    className,
    verticalAlign = "center",
    suffix,
    isVertical,
    error
  } = props;

  const errMsg =
    error && error.length > 0
      ? error === "REQUIRED"
        ? `กรุณากรอก ${label}`
        : error
      : undefined;

  if (isVertical) {
    return (
      <div className={`${className} flex flex-col justify-center w-full`}>
        <div className={`${labelClass} flex flex-row items-center`}>
          <div className="text-md lg:text-lg">{label ?? ""}</div>
          {required ? <div className="ml-1 text-error">*</div> : null}
        </div>
        <div className={`${inputClass}`}>{children}</div>
        {errMsg ? (
          <div className="pl-2 mt-1 text-xs text-left text-error">
            {errMsg ?? ""}
          </div>
        ) : null}
      </div>
    );
  }

  // const child = <div className={`${inputClass}`}>{children}</div>;

  return (
    <>
      {/* <div
        className={`${className} flex flex-col justify-center w-full lg:hidden`}>
        <div className={`${labelClass} flex flex-row items-center mb-2`}>
          <div className="text-md lg:text-lg">{label ?? ""}</div>
          {required ? <div className="ml-1 text-error">*</div> : null}
        </div>
        {child}
        {errMsg ? (
          <div className="pl-2 mt-1 text-xs text-left text-error">
            {errMsg ?? ""}
          </div>
        ) : null}
      </div> */}

      <div
        className={`${className} justify-center items-${verticalAlign} gap-4 flex flex-col items-start  lg:items-center lg:flex-row`}>
        <div className={`${labelClass}  flex flex-row items-center`}>
          <div className="text-lg">{label ?? ""}</div>
          {required ? <div className="text-[red]">*</div> : null}
        </div>
        <div className={`${inputClass}`}>
          <div className="flex flex-col w-full">
            {children}
            {errMsg ? (
              <div className="pl-2 mt-1 text-xs text-left text-error">
                {errMsg ?? ""}
              </div>
            ) : null}
          </div>
        </div>
        {suffix ? <div className="text-lg">{suffix}</div> : null}
      </div>
    </>
  );
};
