// import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import axios from '@/configs/axios';
import { showErrorDialog } from '@/helpers/showErrorDialog';
import { User } from '@/interfaces/User';
import { SignInResponse } from './SignInResponse';

interface AuthStore {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  loginAsync: (data: SignInResponse) => void;
  fetchProfileAsync: () => void;
  logout: () => void;
  userRole?: number;
}

const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        accessToken: null,
        refreshToken: null,
        user: null,
        userRole: null,
        logout: () =>
          set(() => ({
            accessToken: null,
            refreshToken: null,
            user: null,
          })),
        loginAsync: (data: SignInResponse) =>
          set(() => ({
            accessToken: data?.token?.accessToken,
            refreshToken: data?.token?.refreshToken,
            user: data.user,
          })),
        fetchProfileAsync: async () => {
          try {
            const res = await axios.get('/users/me');
            set(() => ({
              user: res.data,
            }));
          } catch (error) {
            showErrorDialog(error);
          }
        },
      }),
      {
        name: 'auth-store',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export default useAuthStore;
