 
export enum UserRoles {
  PATIENT = "PATIENT",
  ADMIN = "ADMIN"
}

export enum EducationLevel {
  HIGH_SCHOOL = "HIGH_SCHOOL",
  BACHELOR = "BACHELOR",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  ABOVE_BACHELOR = 'ABOVE_BACHELOR'
}
