/* eslint-disable react-refresh/only-export-components */
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import ProtectedRoute from "@/components/layouts/ProtectedRoute";
import AdminAppLayout from "@/components/layouts/admin/AdminAppLayout";
import CreateMediaLinkPage from "@/pages/admin/manage/health-contents/create"; 

const CAMICUFormPage = lazy(
  () => import("@/pages/admin/manage/name-list/cam-icu")
);
const CAMTFormPage = lazy(() => import("@/pages/admin/manage/name-list/cam-t"));
const CAMFourATFormPage = lazy(
  () => import("@/pages/admin/manage/name-list/four-at")
);

const SelfReportViewPage = lazy(
  () => import("@/pages/admin/manage/name-list/self-report")
);

const AssessmentOfLifeAbilityForm = lazy(
  () => import("@/pages/admin/manage/name-list/self-report/AssessmentOfLifeAbilityForm")
);

const DailyActivityAssessmentForm = lazy(
  () => import("@/pages/admin/manage/name-list/self-report/DailyActivityAssessmentForm")
);

const GeneralAssessmentForm = lazy(
  () => import("@/pages/admin/manage/name-list/self-report/GeneralAssessmentForm")
);

const SelfReportPage = lazy(
  () => import("@/pages/app/self-report")
);
const AfterRiskAssessment = lazy(
  () => import("@/pages/admin/manage/name-list/after-risk-assessment")
);
const MOCAPage = lazy(() => import("@/pages/admin/manage/name-list/moca"));

const AdminLoginPage = lazy(() => import("@/pages/admin/auth/login"));
const NotFoundPage = lazy(() => import("@/pages/admin/manage/not-found"));
const AdminDashboardPage = lazy(() => import("@/pages/admin/manage/dashboard"));
const NameListPage = lazy(() => import("@/pages/admin/manage/name-list"));
const HealthContents = lazy(
  () => import("@/pages/admin/manage/health-contents")
);
const ProjectConfigsPage = lazy(
  () => import("@/pages/admin/manage/project-configs")
);

export const adminRoutes = [
  {
    path: "/admin",
    element: (
      <ProtectedRoute isAdmin>
        <AdminAppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "project-configs",
        element: <ProjectConfigsPage />
      },
      {
        path: "dashboard",
        element: <AdminDashboardPage />
      },
      {
        path: "health-contents",
        children: [
          {
            path: "",
            element: <HealthContents />
          },
          {
            path: "create",
            element: <CreateMediaLinkPage />
          },
          {
            path: ":id/edit",
            element: <CreateMediaLinkPage />
          }
        ]
      },
      {
        path: "name-list",
        children: [
          {
            path: "",
            element: <NameListPage />
          },
          {
            path: "assessments/cam-icu/:patientId",
            element: <CAMICUFormPage />
          },
          {
            path: "assessments/cam-t/:patientId",
            element: <CAMTFormPage />
          },
          {
            path: "assessments/4at/:patientId",
            element: <CAMFourATFormPage />
          },
          {
            path: ":id/self-report",
            element: <SelfReportViewPage />
          },
          {
            path: ":id/AssessmentOfLifeAbilityForm",
            element: <AssessmentOfLifeAbilityForm />
          },
          {
            path: ":id/DailyActivityAssessmentForm",
            element: <DailyActivityAssessmentForm />
          },
          {
            path: ":id/GeneralAssessmentForm",
            element: <GeneralAssessmentForm />
          },
          {
            path: ":id/:userId/self-report",
            element: <SelfReportPage />
          },
          {
            path: ":id/:userId/:formType/after-risk-assessment",
            element: <AfterRiskAssessment />
          },
          {
            path: ":id/:userId/:formType/:surgeryDate/moca",
            element: <MOCAPage />
          }
        ]
      },
      {
        path: "",
        element: <Navigate to="dashboard" replace />
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  },
  {
    path: "/admin/auth",
    children: [
      {
        path: "login",
        element: <AdminLoginPage />
      }
    ]
  }
];
