import { SelfReportFormAnswersType } from "@/interfaces/SelfReportFormAnswer";
import selfReportFormAnswerService from "@/services/self-report-form-answer.service";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface SelfReportFormData {
  userId?: string;
  formType?: SelfReportFormAnswersType;
  // step 1
  assessmentDate?: Date;
  surgeryDate?: Date;
  age?: number;
  gender?: string;
  weight?: number;
  height?: number;
  bmi?: number;
  educationLevel?: number;

  // step 3
  disease_hypertension?: number;
  disease_coronary_heart_disease?: number;
  disease_heart_valve_disease?: number;
  disease_atrial_fibrillation?: number;
  disease_heart_failure?: number;
  disease_peripheral_artery_disease?: number;
  disease_warfarin?: number;
  disease_diabetes?: number;
  disease_hyperlipidemia?: number;
  disease_hypothyroidism?: number;
  disease_hyperthyroidism?: number;
  disease_chronic_obstructive_pulmonary_disease?: number;
  disease_asthma?: number;
  disease_upper_respiratory_infection?: number;
  disease_dementia?: number;
  disease_depression?: number;
  disease_stroke?: number;
  disease_hemiplegia?: number;
  disease_paraplegia?: number;

  // drink
  disease_drink?: number;
  disease_drink_start_age?: number;
  disease_drink_quantity?: number;
  disease_stop_drink_start_age?: number;
  disease_stop_drink_stop_age?: number;
  disease_stop_drink_quantity?: number;

  // smoke
  disease_smoke?: number;
  disease_smoke_start_age?: number;
  disease_smoke_quantity?: number;
  disease_stop_smoke_start_age?: number;
  disease_stop_smoke_stop_age?: number;
  disease_stop_smoke_quantity?: number;

  // other
  disease_liver?: number;
  disease_gastric_ulcer?: number;
  disease_cancer?: number;
  disease_advanced_stage_cancer?: number;
  disease_heart_valve_disease_post_rheumatic_fever?: number;
  disease_skin_ulcers?: number;
  disease_aids?: number;
  disease_electrolyte_imbalance?: number;
  disease_arthritis?: number;
  disease_chronic_kidney?: number;

  disease_vision_problem?: string;
  disease_hear_problem?: string;

  // step 4
  brain_assessment_family_appearance_memory?: number;
  brain_assessment_family_name_memory?: number;
  brain_assessment_relative_detail_memory?: number;
  brain_assessment_recent_event_memory?: number;
  brain_assessment_recent_conversation_memory?: number;
  brain_assessment_continuous_speech_memory?: number;
  brain_assessment_current_residence_memory?: number;
  brain_assessment_date_memory?: number;
  brain_assessment_home_storage_memory?: number;
  brain_assessment_object_placement_memory?: number;
  brain_assessment_childhood_event_memory?: number;
  brain_assessment_childhood_learning_memory?: number;
  brain_assessment_significant_past_event_knowledge?: number;
  brain_assessment_daily_life_adaptation?: number;
  brain_assessment_home_appliance_usage?: number;
  brain_assessment_new_appliance_learning_ability?: number;
  brain_assessment_general_learning_ability?: number;
  brain_assessment_new_word_understanding?: number;
  brain_assessment_article_understanding?: number;
  brain_assessment_broadcast_content_following?: number;
  brain_assessment_communication_ability?: number;
  brain_assessment_daily_decision_making?: number;
  brain_assessment_expenditure_management?: number;
  brain_assessment_money_allocation?: number;
  brain_assessment_quantity_estimation?: number;
  brain_assessment_event_understanding_and_reasoning?: number;
  brain_assessment_song_or_prayer_recollection?: number;
  brain_assessment_appropriate_tool_selection?: number;
  brain_assessment_repetitive_speech?: number;
  brain_assessment_daily_routine_performance?: number;
  brain_assessment_independent_travel_ability?: number;
  brain_assessment_familiar_task_performance?: number;

  // step 5
  depress_q_1_1?: number;
  depress_q_1_2?: number;
  depress_q_2_1?: number;
  depress_q_2_2?: number;
  depress_q_2_3?: number;
  depress_q_2_4?: number;
  depress_q_2_5?: number;
  depress_q_2_6?: number;
  depress_q_2_7?: number;
  depress_q_2_8?: number;
  depress_q_2_9?: number;
  depress_q_3_1?: number;
  depress_q_3_2?: number;
  depress_q_3_3?: number;
  depress_q_3_4?: number;
  depress_q_3_5?: number;
  depress_q_3_6?: number;
  depress_q_3_7?: number;
  depress_q_3_8?: number;

  // step 6
  frailty_q_1_1?: number;
  frailty_q_1_2?: number;
  frailty_q_1_3?: number;
  frailty_q_1_4?: number;
  frailty_q_2?: number;
  frailty_q_3?: number;

  // step 7
  life_ability_q_1_1?: number;
  life_ability_q_1_2?: number;
  life_ability_q_1_3?: number;
  life_ability_q_1_4?: number;
  life_ability_q_1_5?: number;
  life_ability_q_1_6?: number;
  life_ability_q_1_7?: number;
  life_ability_q_1_8?: number;
  life_ability_q_1_9?: number;
  life_ability_q_1_10?: number;

  // step 8
  daily_activity_q_1_1?: number;
  daily_activity_q_1_2?: number;
  daily_activity_q_1_3?: number;
  daily_activity_q_1_4?: number;
  daily_activity_q_1_5?: number;
  daily_activity_q_1_6?: number;
  daily_activity_q_1_7?: number;
  daily_activity_q_1_8?: number;
  daily_activity_q_1_9?: number;
  daily_activity_q_1_10?: number;

  // step 9
  general_assessment_q_1_1?: number;
  general_assessment_q_1_2?: number;
  general_assessment_q_1_3?: number;
  general_assessment_q_1_4?: number;
  general_assessment_q_1_5?: number;
  general_assessment_health_score?: number;
}
interface SelfReportFormStore {
  userId?: string;
  formId?: string;
  formData?: SelfReportFormData;
  onFormDataChange: (data?: SelfReportFormData) => void;
  setFormId: (id?: string) => void;
  clearFormData: () => void;
  onSaveDraftAsync: (activeTab: number) => Promise<void>;
  onSaveAsync: () => Promise<void>;
  actionType: string;
  setActionType: (value: string) => void;
  setUserId: (id?: string) => void;
}

const useSelfReportStore = create<SelfReportFormStore>()(
  devtools(
    persist(
      (set) => ({
        formData: {} as any,
        actionType: "NEXT",
        setActionType(value: string) {
          set((state) => {
            return {
              ...state,
              actionType: value
            };
          });
        },
        onFormDataChange(data: any) {
          const newData = data ?? {};

          set((state) => {
            const newFormData = {
              ...(state.formData ? state.formData : {}),
              ...newData
            };
            return {
              ...state,
              formData: newFormData
            };
          });
        },
        clearFormData() {
          set((state) => ({
            ...state,
            formData: undefined
          }));
        },
        setFormId(id?: string) {
          set((state) => ({
            ...state,
            formId: id
          }));
        },
        setUserId(id?: string) {
          set((state) => ({
            ...state,
            userId: id
          }));
        },
        async onSaveDraftAsync(activeTab: number) {
          let body: any = {};
          const state = useSelfReportStore.getState();
          const { formData, formId } = state;
          for (const key in formData) {
            const value = formData[key as keyof SelfReportFormData];
            let realValue;
            if (value === "") {
              realValue = undefined;
            } else {
              realValue = value;
            }
            body[key] = realValue;
          }
          body = {
            ...body,
            activeStep: activeTab ?? 1,
            id: formId
          };
          const res = await selfReportFormAnswerService.create(body);
          set((state) => ({
            ...state,
            formId: res.id
          }));
        },
        async onSaveAsync() {
          let body: any = {};
          const state = useSelfReportStore.getState();
          const { formData, formId } = state;
          for (const key in formData) {
            const value = formData[key as keyof SelfReportFormData];
            let realValue;
            if (value === "") {
              realValue = undefined;
            } else {
              realValue = value;
            }
            body[key] = realValue;
          }
          body = {
            ...body,
            isSuccess: true,
            id: formId
          };
          const res = await selfReportFormAnswerService.create(body);
          set((state) => ({
            ...state,
            formId: res.id
          }));
        }
      }),
      {
        name: "self-report-form-store",
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);

export default useSelfReportStore;
