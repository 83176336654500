import axios from "@/configs/axios";
import { APIPaginatedResponse } from "@/interfaces/APIPaginatedResponse";
import { SelfReportFormAnswer } from "@/interfaces/SelfReportFormAnswer";

const BASE_URL = `self-report-answers`;

const create = async (body: any): Promise<any> => {
  const res = await axios.post(BASE_URL, body);
  return res.data;
};

const list = async (
  params?: any
): Promise<APIPaginatedResponse<SelfReportFormAnswer[]>> => {
  const res = await axios.get(BASE_URL, {
    params
  });
  return res.data;
};

const get = async (id: string): Promise<SelfReportFormAnswer> => {
  const res = await axios.get(`${BASE_URL}/${id}`);
  return res.data;
};

const remove = async (id: string): Promise<void> => {
  await axios.delete(`${BASE_URL}/${id}`);
};

const confirm = async (
  id: string,
  body: { inhalation: number; blood_transfusion: number }
): Promise<any> => {
  const res = await axios.post(`${BASE_URL}/${id}/post-surgery/confirm`, body);
  return res.data;
};

const updateAssessmentOfLifeAbilityForm = async (id: string, body : {
  life_ability_q_1_1 : number,
  life_ability_q_1_2 : number,
  life_ability_q_1_3 : number,
  life_ability_q_1_4 : number,
  life_ability_q_1_5 : number,
  life_ability_q_1_6 : number,
  life_ability_q_1_7 : number,
  life_ability_q_1_8 : number,
  life_ability_q_1_9 : number,
  life_ability_q_1_10 : number
}): Promise<any> => {
  const res = await axios.post(`${BASE_URL}/${id}/updateAssessmentOfLifeAbilityForm`, body);
  return res.data;
};

const updateDailyActivityAssessmentForm = async (id: string, body : {
  daily_activity_q_1_1 : number,
  daily_activity_q_1_2 : number,
  daily_activity_q_1_3 : number,
  daily_activity_q_1_4 : number,
  daily_activity_q_1_5 : number,
  daily_activity_q_1_6 : number,
  daily_activity_q_1_7 : number,
  daily_activity_q_1_8 : number,
}): Promise<any> => {
  const res = await axios.post(`${BASE_URL}/${id}/updateDailyActivityAssessmentForm`, body);
  return res.data;
};

const updateGeneralAssessmentForm = async (id: string, body : {
  general_assessment_q_1_1 : number,
  general_assessment_q_1_2 : number,
  general_assessment_q_1_3 : number,
  general_assessment_q_1_4 : number,
  general_assessment_q_1_5 : number,
  general_assessment_health_score : number
}): Promise<any> => {
  const res = await axios.post(`${BASE_URL}/${id}/updateGeneralAssessmentForm`, body);
  return res.data;
};

export default {
  create,
  list,
  confirm,
  get,
  remove,
  updateAssessmentOfLifeAbilityForm,
  updateDailyActivityAssessmentForm,
  updateGeneralAssessmentForm
};
