/* eslint-disable react-refresh/only-export-components */
import { createBrowserRouter } from "react-router-dom";
import React, { lazy } from "react";
import { adminRoutes } from "./admin-router";

import ProtectedRoute from "@/components/layouts/ProtectedRoute";
import PatientAppLayout from "@/components/layouts/PatientAppLayout";

const PatientLoginPage = lazy(() => import("@/pages/auth/login"));
const PatientRegisterPage = lazy(() => import("@/pages/auth/register"));
const NotFoundPage = lazy(() => import("@/pages/admin/manage/not-found"));
const RiskAssessment = lazy(() => import("@/pages/app/risk-assessment"));
const RiskAssessmentResultPage = lazy(
  () => import("@/pages/app/risk-assessment/result")
);
const ContentPage = lazy(() => import("@/pages/app/content"));

const HomePage = lazy(() => import("@/pages/app"));
const PersonalAssessmentPage = lazy(
  () => import("@/pages/app/personal-assessment/page")
);
const SelfReportPage = lazy(() => import("@/pages/app/self-report"));
const AssessmentFormPage = lazy(() => import("@/pages/app/assessment-form"));

const AppointmentsPage = lazy(() => import("@/pages/app/appointments"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <PatientAppLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <HomePage />
      },
      {
        path: "personal-assessment",
        element: <PersonalAssessmentPage />
      },
      {
        path: "self-report/:id",
        element: <SelfReportPage />
      },
      {
        path: "assessment-form",
        element: <AssessmentFormPage />
      },
      {
        path: "appointments",
        element: <AppointmentsPage />
      }
    ]
  },
  {
    path: "/auth",
    element: <PatientAppLayout />,
    children: [
      {
        path: "/auth/login",
        element: <PatientLoginPage />
      },
      {
        path: "/auth/register",
        element: <PatientRegisterPage />
      }
    ]
  },
  {
    path: "content/:name",
    element: <PatientAppLayout />,
    children: [
      {
        path: "",
        element: <ContentPage />
      }
    ]
  },
  {
    path: "risk-assessment",
    element: <PatientAppLayout />,
    children: [
      {
        path: "",
        element: <RiskAssessment />
      },
      {
        path: "result/:id",
        element: <RiskAssessmentResultPage />
      }
    ]
  },
  ...adminRoutes,

  {
    path: "*",
    element: <NotFoundPage />
  }
]);
