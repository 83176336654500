import useAuthStore from "@/store/useAuthStore";
import UserButton from "./admin/UserButton";
import { Link } from "react-router-dom";

export default function NavBar() {
  const accessToken = useAuthStore((state) => state.accessToken);
  const isLogin = accessToken && accessToken.length > 0;

  return (
    <div className="w-full bg-primary">
      <div className="container p-4 flex flex-col md:flex-row md:justify-between items-center bg-primary">
        <Link
          to="/"
          className="flex-1 h-[80px] xl:h-[120px] max-w-xl xl:max-w-4xl mb-4 lg:mb-0 ">
          <img src="/nav-logo.png" alt="" />
        </Link>
        {isLogin ? (
          <div className="flex flex-row justify-end  w-full md:w-[fit-content]">
            <div className=" p-2 px-4 bg-white rounded">
              <UserButton />
            </div>
          </div>
        ) : (
          <Link to="/admin/login" className="self-end lg:self-center text-sm lg:text-lg text-white">
            สำหรับผู้ดูแลระบบและบุคลากร 
          </Link>
        )}
      </div>
    </div>
  );
}
