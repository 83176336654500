import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import { Suspense } from "react";

const LoadingScreen = () => (
  <div className="flex flex-col items-center justify-center w-full h-full">
    <span className="loading loading-spinner loading-lg"></span>
  </div>
);

const PatientAppLayout = () => {
  return (
    <>
      <NavBar />
      <main className="relative w-full h-full px-4 flex-[1]">
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </main>
    </>
  );
};

export default PatientAppLayout;
