import { FiPrinter, FiUser } from "react-icons/fi";

interface PageTitleProps {
  title?: string;
  showActions?: boolean;
  className?: string;
  showAddPatient?: boolean;
}

export const PageTitle = (props: PageTitleProps) => {
  return (
    <div className="flex flex-row justify-between py-2 lg:py-6 page-title">
      <div
        className={`text-2xl lg:text-2xl font-[500] ${props.className ?? ""}`}>
        {props.title ?? ""}
      </div>

      {props.showActions ? (
        <div className="flex flex-row items-center gap-4">
          {props.showAddPatient ? (
            <a href="/auth/register" target="_blank" className="flex flex-row items-center gap-2">
              <div className="text-lg text-primary">เพิ่มคนไข้</div>
              <div className="text-3xl">
                <FiUser />
              </div>
            </a>
          ) : null}
          <div className="h-full w-[1px] bg-neutral"></div>
          <button className="flex flex-row items-center gap-2">
            <div className="text-lg text-primary">Print</div>
            <div className="text-3xl">
              <FiPrinter />
            </div>
          </button>
          <div className="h-full w-[1px] bg-neutral"></div>
          <button className="flex flex-row items-center gap-2">
            <div className="text-lg text-primary">Export</div>
            <div className="relative w-8 h-8 overflow-hidden">
              <img src="/images/Excel.png" />
            </div>
          </button>
        </div>
      ) : null}
    </div>
  );
};
