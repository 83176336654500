import React, { ChangeEvent, forwardRef } from "react";

interface LabelValue {
  label: string;
  value?: string | number;
}

interface RadioGroupProps {
  name: string;
  options: LabelValue[];
  onChange?: (value?: any) => void;
  value?: string | number | undefined | null;
  isVertical?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  error?: string;
}

const RadioGroup = forwardRef(
  (props: RadioGroupProps, ref: React.Ref<HTMLDivElement>) => {
    const { name, options, type = "radio", error } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // setValue(event.target.value);
      props.onChange?.(event);
    };

    return (
      <div className="flex flex-col" ref={ref}>
        <div
          className={`flex flex-col items-start gap-4 pt-2 ${
            props.isVertical ? "" : "lg:flex-wrap lg:flex-row lg:gap-6"
          }`}>
          {options.map((option, i) => {
            const l = option.label;
            const v = option.value ?? option.label;
            const checked = props.value == v;

            return (
              <label
                key={`${name}-radio-${i}-${v}`}
                className="items-center gap-2 cursor-pointer label">
                <input
                  type={type}
                  className={`${type} ${
                    type === "checkbox" ? "rounded" : ""
                  } checked:bg-primary ${props.error ? "radio-error" : ""}`}
                  value={v}
                  name={name}
                  checked={checked}
                  onChange={!props.readOnly ? handleChange : undefined}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                />
                <span className="mt-1 text-sm md:text-base lg:text-base">
                  {l}
                </span>
              </label>
            );
          })}
        </div>
        {error ? <div className="mt-2 text-xs text-error">{error}</div> : null}
      </div>
    );
  }
);

export default RadioGroup;
