
import { FiMenu } from "react-icons/fi";
import UserButton from "./UserButton";

export default function AdminAppNavBar() {
  return (
    <div className="flex flex-row items-center justify-between w-full p-4 bg-[white]">
      <div className="flex flex-row items-center gap-4">
        <label htmlFor="my-drawer" className="cursor-pointer">
          <FiMenu />
        </label>
        <div className="text-md">ผู้ดูแลระบบ สำหรับบุคลากร</div>
      </div>
      <UserButton isAdmin />
    </div>
  );
}
