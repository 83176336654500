import { FiCheckSquare, FiHome, FiMessageSquare } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

interface MenuItem {
  title: string;
  path: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  items?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: FiHome
  },
  {
    title: "จัดการข้อมูลเกี่ยวกับโครงการ",
    path: "/admin/project-configs",
    icon: FiCheckSquare
  },

  {
    title: "รายชื่อผู้เข้าร่วมโครงการและรายละเอียดการติดตาม",
    path: "/admin/name-list",
    icon: FiMessageSquare
  },
  // {
  //   title: "จัดการ content สุขภาพ",
  //   path: "/admin/health-contents",
  //   icon: FiCheckSquare
  // }
];

export default function AdminAppSideBar() {
  const { pathname } = useLocation();

  const isMenuItemActive = (item: MenuItem) => {
    return pathname === item.path;
  };

  return (
    <div className="z-40 drawer-side lg:bg-white">
      <label htmlFor="my-drawer" className="drawer-overlay"></label>
      <aside className="h-full w-[300px] bg-base-100">
        <div className="p-4 py-6">
          <img src="/images/logo.png" className="w-[60px] h-[60px]" alt="" />
        </div>
        <div className="flex flex-col p-0 mb-10 bg-white menu menu-compact">
          {menuItems.map((item) => {
            const isActive = isMenuItemActive(item);
            return (
              <div key={item.path} className="w-full">
                <Link to={item.path} className="cursor-pointer">
                  <div
                    className={` flex items-center w-full gap-4 px-2 px-6 py-4 ${
                      isActive ? "bg-primary" : "hover:pl-5"
                    }`}>
                    <span className="flex-none pr-2">
                      {item.icon && (
                        <item.icon
                          className={`menu-icon ${
                            isActive ? "text-white" : ""
                          }`}
                          fontSize={20}
                        />
                      )}
                    </span>
                    <span
                      className={`flex-1 text-md ${
                        isActive ? "text-white" : ""
                      }`}>
                      {item.title}
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </aside>
    </div>
  );
}
