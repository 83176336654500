import Axios from "axios";

import useAuthStore from "@/store/useAuthStore";

// let isRefreshing = false;
// let failedQueue = [] as any[];

// const processQueue = (error: any, token: any = null) => {
//   failedQueue.forEach((prom: any) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

const axios = Axios.create({
  baseURL: import.meta.env.VITE_APP_API_HOST + "/api",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    credentials: "omit"
  },
  withCredentials: false
});

axios.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;

    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const accessToken = useAuthStore.getState().accessToken;

      // if (refreshToken) {
      //   if (isRefreshing) {
      //     return new Promise(function (resolve, reject) {
      //       failedQueue.push({ resolve, reject });
      //     })
      //       .then((token) => {
      //         originalRequest.headers["Authorization"] = "Bearer " + token;
      //         return axios(originalRequest);
      //       })
      //       .catch((err) => {
      //         return err;
      //       });
      //   }

      //   try {
      //     originalRequest._retry = true;
      //     isRefreshing = true;

      //     const res = await authService.refreshToken(refreshToken);

      //     useAuthStore.setState({
      //       accessToken: res.accessToken,
      //       refreshToken: res.refreshToken
      //     });

      //     axios.defaults.headers.common.Authorization = `Bearer ${res.accessToken}`;
      //     processQueue(null, res.accessToken);

      //     const contentType = originalRequest.headers["Content-Type"];
      //     console.log(contentType, " <------ contentType");
      //     const newContentType =
      //       contentType && contentType?.includes("json") ? contentType : null;
      //     console.log(newContentType, " <------ newContentType");

      //     return axios({
      //       ...originalRequest,
      //       headers: {
      //         ...originalRequest.headers,
      //         "Content-Type": newContentType
      //       }
      //     });
      //   } catch (error) {
      //     processQueue(error, null);
      //     if (
      //       error instanceof AxiosError &&
      //       (error.status === 401 ||
      //         error?.response?.data?.message === "Unauthorized.")
      //     ) {
      //       useAuthStore.setState({
      //         accessToken: null,
      //         refreshToken: null,
      //         user: null
      //       });

      //       (<any>window).location = "/auth/login";

      //       return;
      //     } else {
      //       return Promise.reject(error);
      //     }
      //   } finally {
      //     isRefreshing = false;
      //   }
      // }

      if (accessToken) {
        useAuthStore.setState({
          accessToken: null,
          refreshToken: null,
          user: null
        });

        // (<any>window).location = "/auth/login";
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
