import { Outlet } from "react-router-dom";
import AdminAppNavBar from "./AdminAppNavBar";
import AdminAppSideBar from "./AdminAppSideBar";
import { Suspense } from "react";

const LoadingScreen = () => (
  <div className="flex flex-col items-center justify-center w-full h-full">
    <span className="loading loading-spinner loading-lg"></span>
  </div>
);

export default function AdminAppLayout() {
  return (
    <div className="bg-screen">
      <div className="h-full bg-base drawer lg:drawer-open">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="h-screen max-h-screen overflow-y-auto drawer-content scroll-smooth" id="drawer-content">
          <div className="container p-8">
            <AdminAppNavBar />
            <div className="py-4">
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
        <AdminAppSideBar />
      </div>
    </div>
  );
}
