import useAuthStore from "@/store/useAuthStore";
import useSelfReportStore from "@/store/useSelfReportStore";

interface UserButtonProps {
  isAdmin?: boolean;
}

export default function UserButton(props: UserButtonProps) {
  const clearFormData = useSelfReportStore((state) => state.clearFormData);
  const setFormId = useSelfReportStore((state) => state.setFormId);

  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  if (!user) return <div />;

  return (
    <>
      <div className="rounded dropdown">
        <label tabIndex={0} className="flex flex-row gap-4 cursor-pointer">
          <div className="flex flex-col items-end justify-between">
            <div className="font-bold text-md">{`${user.firstName} ${user.lastName}`}</div>
            <div className="text-xs font-[300] text-neutral">{user.role}</div>
          </div>
          <div
            className="relative w-10 h-10 rounded-full avatar"
            style={{
              boxShadow:
                "0 4px 8px 0 rgba(34, 41, 47, 0.12), 0 2px 4px 0 rgba(34, 41, 47, 0.08)"
            }}>
            <div className="relative w-10 h-10 overflow-hidden border rounded-full">
              <img src="/images/avatar.png" alt="" className="object-cover" />
            </div>
            <div className="w-2 h-2 bg-success rounded-full absolute bottom-0 right-0 mr-[2px] mb-[2px]"></div>
          </div>
        </label>

        <ul
          tabIndex={0}
          className="dropdown-content rounded z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
          <li>
            <button>วิธีการใช้งาน</button>
          </li>
          <li>
            <button
              onClick={() => {
                logout();
                clearFormData();
                setFormId(undefined);
                window.location.href = props.isAdmin
                  ? "/admin/auth/login"
                  : "/auth/login";
              }}>
              ออกจากระบบ
            </button>
          </li>
        </ul>
      </div>
      {/* <Menu as="div" className="relative">
        <Menu.Button className="">

        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-1 bg-white card">
          <Menu.Item>
            {({ active }) => (
              <a className={`p-2`} href="/account-settings">
                ออกจากระบบ
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu> */}
    </>
  );
}
