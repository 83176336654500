import axios from "@/configs/axios";
import { APIPaginatedResponse } from "@/interfaces/APIPaginatedResponse";

class ApiService<T, CreateT, UpdateT, ListT> {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async create(body: CreateT): Promise<T> {
    const res = await axios.post(this.baseUrl, body);
    return res.data;
  }

  async update(id: string, body: UpdateT): Promise<T> {
    const res = await axios.patch(`${this.baseUrl}/${id}`, body);
    return res.data;
  }

  async list(params?: ListT): Promise<APIPaginatedResponse<T[]>> {
    const res = await axios.get(this.baseUrl, { params });
    return res.data;
  }

  async get(id: string): Promise<T> {
    const res = await axios.get(`${this.baseUrl}/${id}`);
    return res.data;
  }

  async remove(id: string): Promise<void> {
    await axios.delete(`${this.baseUrl}/${id}`);
  }
}

export default ApiService;
