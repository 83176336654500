import BaseTextInput from "@/components/form/BaseTextInput";
import { FormItem } from "@/components/form/FormItem";
import RadioGroup from "@/components/form/RadioGroup";
import { PageTitle } from "@/components/layouts/admin/PageTitle";
import { MediaLinkService } from "@/services/media-link.service";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

interface FormValues {
  name: string;
  url: string;
  mediaType: string;
}

export default function CreateMediaLinkPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const service = new MediaLinkService();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<FormValues>();

  const onSubmit = async (values: FormValues) => {
    Swal.showLoading();
    try {
      if (id) {
        await service.update(id, values);
      } else {
        await service.create(values);
      }
      Swal.close();
      await Swal.fire("สำเร็จ", "บันทึกสำเร็จ", "success");
      navigate("/admin/health-contents");
    } catch (error) {
      Swal.close();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      Swal.showLoading();
      try {
        const res = await service.get(id);
        setValue("name", res.name);
        setValue("url", res.url);
        setValue("mediaType", res.mediaType);
        Swal.close();

      } catch (error) {
        Swal.close();
      }
    };

    if (id) {
      fetchData();
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full w-max-xs">
        <PageTitle title="เพิ่มข้อมูล" />
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 p-6 py-10 card-content">
            <FormItem
              label="ชื่อสื่อความรู้"
              isVertical
              error={errors.name?.message}
              required>
              <BaseTextInput
                control={control}
                name="name"
                error={errors.name?.message}
              />
            </FormItem>

            <FormItem
              label="ลิงค์สื่อความรู้"
              isVertical
              error={errors.url?.message}
              required>
              <BaseTextInput
                control={control}
                name="url"
                error={errors.url?.message}
              />
            </FormItem>

            <FormItem
              label="การแสดงผล"
              labelClass="text-xl font-semi-bold"
              isVertical
              className="w-full"
              error={errors.mediaType?.message}
              verticalAlign="start"
              required>
              <div className="flex flex-wrap w-full gap-4">
                <Controller
                  name="mediaType"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        isVertical
                        name="inhalation"
                        options={[
                          {
                            label: "กลุ่มเสี่ยงปกติ",
                            value: "LOW_RISK"
                          },
                          {
                            label: "กลุ่มเสี่ยงสูง",
                            value: "HIGH_RISK"
                          }
                        ]}
                        onChange={field.onChange}
                        value={field.value as any}
                        error={errors.mediaType?.message}
                      />
                    );
                  }}
                />
              </div>
            </FormItem>

            <div>
              <button type="submit" className="btn btn-primary min-w-[120px]">
                บันทึก
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
