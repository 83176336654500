import { PaginationQuery } from "@/interfaces/PaginationQuery";
import ApiService from "./api.service";
import { MediaLink } from "@/interfaces/MediaLink";

interface CreateT {
  name: string;
  url: string;
  mediaType: string;
}

interface UpdateT {
  name: string;
  url: string;
  mediaType: string;
}

interface FindT extends PaginationQuery {}

export class MediaLinkService extends ApiService<
  MediaLink,
  CreateT,
  UpdateT,
  FindT
> {
  constructor() {
    super("media-links");
  }
}
