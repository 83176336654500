/* eslint-disable react-hooks/rules-of-hooks */
import classNames from "classnames";
import { ReactElement } from "react";
import { useState } from "react";
import { Control, useController, UseControllerProps } from "react-hook-form";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";

interface FormTextInputProps extends UseControllerProps {
  label?: string;
  required?: boolean;
  isVertical?: boolean;
  className?: string;
  placeholder?: string;
  type?: string;
  inputClassName?: string;
  labelClassName?: string;
  name: string;
  control?: Control<any>;
  error?: string;
  inputProps?: any;
  hideError?: boolean;
  suffixIcon?: ReactElement;
  allowDot?: boolean;
  actions?: ReactElement;
  value?: string;
  onChange?: (e: any) => void;
  isTextArea?: boolean;
}

const BaseTextInput = (props: FormTextInputProps) => {
  const {
    type,
    placeholder,
    inputClassName,
    name,
    error,
    inputProps,
    suffixIcon,
    control,
    actions
  } = props;

  const inputClasses =
    inputClassName ??
    classNames(
      "input input-base input-bordered w-full",
      error ? "input-error" : undefined,
      suffixIcon ? "pr-12" : "",
      props.isTextArea ? "" : "min-h-[1px] h-[45px]"
    );
  const controller = control ? useController(props) : undefined;
  const [visible, setVisible] = useState(false);

  const filterNonNumeric = (e: any) => {
    const regex = props.allowDot ? /[^0-9.]/g : /[^0-9]/g;
    const input = e.target.value.replace(regex, "");
    props.onChange ? props.onChange(input) : controller?.field.onChange(input);
  };

  return (
    <div className="relative">
      <input
        {...(controller?.field ?? {})}
        {...inputProps}
        name={name}
        type={type === "password" ? (visible ? "text" : "password") : "text"}
        className={inputClasses}
        placeholder={placeholder}
        value={controller ? controller?.field?.value ?? "" : props.value ?? ""}
        onChange={
          type === "number"
            ? filterNonNumeric
            : props.onChange ?? controller?.field?.onChange
        }
      />
      {suffixIcon ? (
        <div className="absolute top-0 bottom-0 right-0 flex flex-col justify-center pr-4 text-xl text-dark">
          {suffixIcon}
        </div>
      ) : null}
      {type === "password" ? (
        <button
          type="button"
          onClick={() => setVisible(!visible)}
          className="absolute top-0 bottom-0 right-0 flex flex-col justify-center pr-4 text-xl text-dark">
          {visible ? <PiEyeSlash /> : <PiEyeLight />}
        </button>
      ) : null}
      {actions ? (
        <div className="absolute right-0 top-0 bottom-0 flex flex-col items-end justify-center pr-4">
          {actions}
        </div>
      ) : null}
    </div>
  );
};

export default BaseTextInput;
